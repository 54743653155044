<template>
  <div class="content-main-top-container">
    <!-- 메뉴 -->
    <div class="lp-menu-div">
      <div class="container flex" style="overflow-y: unset">
        <div v-for="menu in menus"
             :key="menu.value"
             class="lp-menu unselect"
             :class="{'selected-menu':selectedMenu===menu.value}"
             @click="clickMenu(menu)">{{ menu.label }}</div>
      </div>
    </div>

    <!-- 내용 -->
    <div class="container" style="overflow-y: unset; padding: 50px 10px;">

      <!-- 공지사항 내용 -->
      <div class="columns is-multiline" v-if="selectedMenu===1">
        <div class="column is-8" v-if="!isSelectNotice">
          <div class="lp-column-title">새소식</div>
          <!-- 공지사항 목록 -->
          <div>
            <div v-for="notice in notices"
                 :key="notice.id"
                 class="flex board-list"
                 @click="clickNotice(notice)">
              <div class="lp-board-title">{{ notice.title }}</div>
              <div class="lp-created-time">{{ notice.created_time.split(' ')[0] }}</div>
            </div>
            <!-- 페이지네이션 -->
            <lp-paginate :filtered="noticeFiltered"></lp-paginate>
          </div>
        </div>

        <call-center v-if="!isSelectNotice"></call-center>

        <div class="column is-12" v-if="isSelectNotice">
          <!-- 공지사항 상세 -->
          <div>
            <div class="lp-detail-title">{{ notice_detail.title }}</div>
            <div class="lp-created-time">{{ notice_detail.created_time }}</div>
            <div class="lp-detail-content" v-html="notice_detail.content"></div>
            <div class="lp-back-list unselect" @click="clickList">목록으로</div>
          </div>
        </div>
      </div>

      <!-- faq -->
      <div class="columns is-multiline" v-if="selectedMenu===2">
        <faq></faq>
        <call-center></call-center>
      </div>
    </div>

  </div>

</template>

<script>
  import LpPaginate from "../component/LpPaginate";

  import Faq from "../module/Faq";
  import CallCenter from "../module/CallCenter";

  export default {
    components: {
      CallCenter,
      LpPaginate, Faq},
    name: "store",

    data() {
      return {
        selectedMenu:1,
        isSelectNotice:false,
        menus: [
          { label: '새소식', value: 1 },
          { label: 'FAQ', value: 2 }
        ],
        notices: [],
        noticeFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 10
        },
        notice_detail: {},
      }
    },
    watch: {
      '$route.query.menu'(n) {
        if(n==='notice') this.selectedMenu = 1;
        else if(n==='faq') this.selectedMenu = 2;
      }
    },
    created() {
      if(this.$route.query.menu) {
        let menu = this.$route.query.menu;
        if(menu==='notice') this.selectedMenu = 1;
        else if(menu==='faq') this.selectedMenu = 2;
      }

      this.getNotice();
      //this.getFaq();
    },
    methods: {
      clickMenu(menu) {
        this.setGa(`고객센터 탭 (${menu.label})`, '클릭', menu.label);
        this.selectedMenu = menu.value;
        if(menu.value===2) this.isSelectNotice=false;
      },
      clickNotice(notice) {
        console.log(notice.id)
        this.$axios.get(`public/board/notice/${notice.id}`).then(res=>{
          this.notice_detail = res.data;
          this.isSelectNotice = true;
        })
      },
      // 목록으로
      clickList() {
        this.getNotice();
        this.isSelectNotice = false;
      },
      getNotice() {
        this.$axios.get('public/board/notice', {
          params: this.noticeFiltered
        }).then(res=>{
          this.notices = res.data.data;
          this.noticeFiltered.page_length = res.data.page_length;
          this.noticeFiltered.cur_page = res.data.cur_page;
          this.noticeFiltered.total_page = res.data.total_page;
        })
      }
    }
  }
</script>

<style scoped>
  .selected-menu {
    color: #000000;
    font-weight: bold;
    border-bottom: 3px solid #ff6600;
  }

  .lp-menu-div {
    border-bottom: 1px solid #eee;
  }

  .lp-menu {
    cursor: pointer;
    padding: 12px;
    font-size: 14px;
    color: #606060;
  }

  .lp-menu:hover {
    color: #606060;
    font-weight: bold;
  }

  .board-list {
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
  }

  .board-list:nth-of-type(odd) {
    background-color: #eee;
  }

  .lp-board-title {
    color: #303030;
  }

  .lp-created-time {
    color: #a0a0a0;
    font-size: 14px;
  }



  .lp-back-list {
    cursor: pointer;
    padding: 8px 16px;
    font-size: 12px;
    border: 1px solid #eee;
    border-radius: 3px;
    width: 85px;
    text-align: center;
  }

  .lp-back-list:hover {
    border: 1px solid #ff6600;
    color: #ff6600;
  }

  .lp-detail-title {
    font-size: 24px;
  }

  .lp-detail-content {
    padding: 40px 0;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    .lp-created-time {
      font-size: 11px;
    }

    .lp-board-title {
      max-width: 70%;
    }
  }

</style>
